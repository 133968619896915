import { render, staticRenderFns } from "./PreLogin.vue?vue&type=template&id=bf9e9a32&scoped=true&"
import script from "./PreLogin.vue?vue&type=script&lang=js&"
export * from "./PreLogin.vue?vue&type=script&lang=js&"
import style0 from "./PreLogin.vue?vue&type=style&index=0&id=bf9e9a32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf9e9a32",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VIcon,VImg,VWindow,VWindowItem})
