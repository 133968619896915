<template>
  <AppPage blank-page container>
    <div class="auth-container">
      <div>
        <v-window v-model="current">
          <v-window-item v-for="screen in screens" :key="screen.id">
            <v-img height="210px" contain :src="screen.image"></v-img>
            <div class="content text-center mt-10">
              <h2>{{ screen.title }}</h2>
              <p class=" text mt-5">
                {{ screen.subtitle }}
              </p>
            </div>
          </v-window-item>
        </v-window>
        <div class="text-center">
          <v-icon
            v-for="i in 3"
            :key="i"
            :color="i - 1 === current ? 'primary' : 'grey-light'"
            size="10px"
            >mdi-record</v-icon
          >
        </div>
      </div>

      <div class="cta-button">
        <app-button @click="nextScreen">{{
          $vuetify.lang.t("$vuetify.get_started")
        }}</app-button>
      </div>
    </div>

    <img class="blue-paw" src="@/assets/images/blue-paw.png" />
    <img class="yellow-paw" src="@/assets/images/yellow-paw.png" />
  </AppPage>
</template>

<script>
import PreImage1 from "@/components/svg/pre-login-1.svg";
import PreImage2 from "@/components/svg/pre-login-2.svg";
import PreImage3 from "@/components/svg/pre-login-3.svg";

export default {
  name: "pre-login",
  data() {
    return {
      current: 0,
    };
  },
  computed: {
    screens() {
      return [
        {
          id: "pre-1",
          title: this.$vuetify.lang.t("$vuetify.adoption_in_few_clicks"),
          subtitle:
            "",
          image: PreImage1,
        },
        {
          id: "pre-2",
          title: this.$vuetify.lang.t("$vuetify.share_animal_care"),
          subtitle:
            "",
          image: PreImage2,
        },
        {
          id: "pre-3",
          title: this.$vuetify.lang.t("$vuetify.share_animal_care"),
          subtitle:
            "",
          image: PreImage3,
        },
      ];
    },
  },
  methods: {
    nextScreen() {
      if (this.current === this.screens.length - 1)
        return this.$router.push("/login");
      this.current++;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  min-height: calc(100vh - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 2.5rem;
  position: relative;
  z-index: 11;
}

.blue-paw {
  position: absolute;
  bottom: 0;
  left: 0;
}

.yellow-paw {
  position: absolute;
  right: 0;
  top: 66%;
}

.content {
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    opacity: 0.5;
  }
}

.cta-button {
  padding-top: 3rem;
}
</style>
